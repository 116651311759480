Module('componentes.utmkeeper', function(Component) {
  'use strict';

  Component.fn.initialize = function(url) {
    this.url = new URL(url);
    var tags = this._utm_tags();

    if (tags != {}) {
      document.querySelectorAll('a').forEach(function(el) {
      try {
        var linkUrl = new URL(el.getAttribute('href'));

        if (linkUrl.href.slice(0,11) != 'about:blank') {
          for(var tag in tags){
            linkUrl.searchParams.set(tag, tags[tag]);
          };

          el.setAttribute('href', linkUrl.href);
        }
      } catch(e) {};
    })
    }
  };

  Component.fn._utm_tags = function() {
    var tags = {};

    this.url.searchParams.forEach(function(value, key) {
      if (key.slice(0,4) == 'utm_') {
        tags[key] = value;
      }
    })

    return tags;
  }
});
