Module('paginas.facil123-funcionalidade', function(Page) {
  'use strict';

  Page.fn.initialize = function() {
    Module.run('componentes.navbar-site');
    Module.run('componentes.features-carousel');
    Module.run('componentes.dynamic-scrollspy');

    $('#scrollspy').DynamicScrollspy({
      affix: false, //affix by default, doesn't work on Bootstrap 4
      tH: 2, //lowest-level header to be included (H2)
      bH: 2, //highest-level header to be included (H6)
      exclude: false, //exclude from the tree/outline any H tags matching this jquery selector
      genIDs: true, //generate random IDs for headers?
      offset: 116, //offset from viewport top for scrollspy
      ulClassNames: 'nav flex-md-column flex-row justify-content-between', //add this class to top-most UL
      activeClass: '', //active class (besides .active) to add
      testing: false //if testing, show heading tagName and ID
    });

    $('#scrollspy ul li a').on('click', function(event) {
      var target = $($(this).attr('href'));

      if(target.length) {
          event.preventDefault();
          $('html, body').animate({
              scrollTop: target.offset().top - 115
          }, 500);
      }
  	});
  };
});
