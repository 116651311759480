Module("paginas.blog-single", function(Page) {
  "use strict";

  Page.fn.initialize = function() {
    Module.run('componentes.navbar-blog');

    $(window).scroll(function() {
      var wintop = $(window).scrollTop(), docheight = $('.content-article-post').height(), winheight = $(window).height();
      var totalScroll = (wintop/(docheight-winheight))*100;

      $(".progress-bar").css("width",totalScroll+"%");
    });
  };
});
