Module('componentes.form-shaker', function(Component) {
  'use strict';

  Component.fn.initialize = function() {
    $('a[href^="#"].scroll-effect').on('click', function(event) {
      var target = $( $(this).attr('href') );

      if(target.length) {
        event.preventDefault();
        $('html, body').animate({
            scrollTop: target.offset().top
        }, 500);
      }
    });

    $('.act-animate-hgl').on('click', function(){
      $('.form-container').addClass('animate-hgl');
      setTimeout(function(){
        $('.form-container').removeClass('animate-hgl');
      },1500);
    });
  };
});
