Module('componentes.phone-mask', function(Component) {
  'use strict';

  Component.fn.initialize = function() {
    var maskBehavior = function (val) {
      return val.replace(/\D/g, '').length === 13 ? '+55 (00) 0 0000-0000' : '+55 (00) 0000-00009';
    }

    var options = {
      onKeyPress: function(val, e, field, options) {
        field.mask(maskBehavior.apply({}, arguments), options);
      }
    }

    $('input[type="tel"]').mask(maskBehavior, options);
  };
});
