// Vendor
import './jquery-global.js';
import 'jquery-number/jquery.number.min'
import 'jquery-mask-plugin/dist/jquery.mask.min'
import 'bootstrap/dist/js/bootstrap.min'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'module/module'

// Application
import './paginas/blog-single.js'
import './paginas/blog-home.js'
import './paginas/blog-search.js'
import './paginas/blog-category.js'
import './paginas/materiais.js'
import './paginas/facil123-funcionalidade.js'
import './paginas/facil123-home.js'
import './paginas/facil123-thank-you-page.js'
import './paginas/facil123-precos.js'
import './paginas/landing-page-1-col.js'
import './paginas/landing-page-2-cols.js'
import './componentes/navbar-site.js'
import './componentes/navbar-blog.js'
import './componentes/navbar-materiais.js'
import './componentes/form-shaker.js'
import './componentes/video-player.js'
import './componentes/features-carousel.js'
import './componentes/phone-mask.js'
import './componentes/dynamic-scrollspy.js'
import './componentes/utmkeeper.js'
import './start.js'

