Module('componentes.navbar-site', function(Component) {
  'use strict';

  Component.fn.initialize = function() {
    $('.navbar-desktop #dropdownFeatures').on('click', function() {
      $('.wide-submenu').toggleClass('open');
    });

    $('.navbar-mobile-site .hamburger').on('click', function() {
      $('.wide-submenu').toggleClass('open');
    });

    $('.wide-submenu a').on('click', function() {
      $('.wide-submenu').removeClass('open');
    });
  };
});
