Module('componentes.features-carousel', function(Component) {
  'use strict';

  Component.fn.initialize = function() {
    $('#pills-tab-select').on('change', function (e) {
      // With $(this).val(), you can **(and have to!)** specify the target in your <option> values.
      $('#pills-tab li a').eq($(this).val()).tab('show');
      // If you do not care about the sorting, you can work with $(this).index().
      // $('#the-tab li a').eq($(this).index()).tab('show');
    });
  };
});
