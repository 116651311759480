Module("paginas.materiais", function(Page) {
  "use strict";

  Page.fn.initialize = function() {
    Module.run('componentes.navbar-materiais');

    var categories = $('section.container-materiais').find('input[id^="filter-category-"]');
    categories.on('click', doFilter);

    var formats = $('section.container-materiais').find('input[id^="filter-format-"]');
    formats.on('click', doFilter);

    function doFilter() {
      hideAllMaterials();
      showMatchedMaterials();
    }

    function hideAllMaterials() {
      for(var i=0; i<categories.length; i++) {
        for(var j=0; j<formats.length; j++) {
          $('.' + categories[i].id + '.' + formats[j].id).hide();
        }
      }
    }

    function showMatchedMaterials() {
      for(var i=0; i<categories.length; i++) {
        for(var j=0; j<formats.length; j++) {
          if (categories.eq(i).prop('checked') && formats.eq(j).prop('checked')) {
            $('.content-materiais').find('.' + categories[i].id + '.' + formats[j].id).show();
          }
        }
      }
    }
  };
});
