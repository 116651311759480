Module('componentes.video-player', function(Component) {
  'use strict';

  Component.fn.initialize = function() {
    // Gets the video src from the data-src on each button
    var $videoSrc;
    $('.video-btn').click(function() {
        $videoSrc = $(this).data( "src" );
    });

    // when the modal is opened autoplay it
    $('#modalTestimonial01').on('shown.bs.modal', function(e) {

      // set the video src to autoplay and not to show related video
      $("#video01").attr('src',$videoSrc + "?rel=0&amp;showinfo=0&amp;autoplay=1" );
    });

    // stop playing the youtube video when I close the modal
    $('#modalTestimonial01').on('hide.bs.modal', function(e) {
      // a poor man's stop video
      $("#video01").attr('src',$videoSrc);
    });


    // when the modal is opened autoplay it
    $('#modalTestimonial02').on('shown.bs.modal', function(e) {

      // set the video src to autoplay and not to show related video
      $("#video02").attr('src',$videoSrc + "?rel=0&amp;showinfo=0&amp;autoplay=1" );
    });

    // stop playing the youtube video when I close the modal
    $('#modalTestimonial02').on('hide.bs.modal', function(e) {
      // a poor man's stop video
      $("#video02").attr('src',$videoSrc);
    });

  };
});
