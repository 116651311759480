Module('componentes.navbar-blog', function(Component) {
  'use strict';

  Component.fn.initialize = function() {
    $('.navbar-mobile-blog .hamburger').on('click', function() {
      $('.navbar-mobile').toggleClass('open');
    });

    $('.navbar-mobile a').on('click', function() {
      $('.navbar-mobile').removeClass('open');
   });
  };
});
