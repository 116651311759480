Module("paginas.facil123-precos", function(Page) {
  "use strict";

  Page.fn.initialize = function() {
    Module.run('componentes.navbar-site');
    Module.run('componentes.video-player');

    $('button#btn-plano-basico').click(function() {
      $('div#modalplano').find('h4').html('Plano Básico');
      $('[name="mkt_plano"]').val('Básico');
    });

    $('button#btn-plano-essencial').click(function() {
      $('div#modalplano').find('h4').html('Plano Essencial');
      $('[name="mkt_plano"]').val('Essencial');
    });

    $('button#btn-plano-profissional').click(function() {
      $('div#modalplano').find('h4').html('Plano Profissional');
      $('[name="mkt_plano"]').val('Profissional');
    });

    $('button#btn-plano-enterprise').click(function() {
      $('div#modalplano').find('h4').html('Plano Enterprise');
      $('[name="mkt_plano"]').val('Enterprise');
    });
  };
});
