(function() {
  'use strict';

  function singleEntryPoint() {
    Module.run('componentes.form-shaker');
    Module.run('componentes.video-player');
    Module.run('componentes.phone-mask');
    new componentes.utmkeeper(window.location.href);

    var page = $('section#dispatcher').attr('page');

    if (page) {
      Module.run(page);
    }
  }

  $(document).ready(singleEntryPoint);
})();
